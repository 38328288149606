<template>
  <LeftSizePreloader v-if="state.preload"/>
  <div v-else class="organization_profile">
    <div class="organization_profile__image" :class="{image: state.orgProfileMenuInfo.image}">
      <img v-if="state.orgProfileMenuInfo.image" :src="state.orgProfileMenuInfo.image" alt="" />
      <a class="organization_profile__image_edit shadow" v-if="state.isOwner" @click="state.showCroppie = true">写真を変更</a>
    </div>
    <div class="organization_profile__information">
      <router-link class="blue-text" :to="{path: state.isOwner ? '/organization/profile' : '/organization/' + state.orgProfileMenuInfo.account_id}">{{ state.orgProfileMenuInfo.name }}</router-link>
    </div>
    <PopupCroppie v-if="state.showCroppie" type="organization" :image="state.orgProfileMenuInfo.image" :id="props.accountId" :boundary="{width: 300, height: 300}" :viewport="{width: 230, height: 200}" :uploadImageNotes="uploadImageNotes" @close="state.showCroppie = false" @changeImage="onFileChange"/>
  </div>
</template>

<script lang="ts" src="@/presentation/components/organization-profile/OrganizationProfile.ts" scoped>
</script>
<style lang="scss" src="@/presentation/components/organization-profile/OrganizationProfile.scss" scoped>
</style>
