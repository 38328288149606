import LanguageUtil from "@/commons/LanguageUtil";
import { useStore } from "vuex";
import { defineComponent, onMounted, reactive, watch, watchEffect, } from "vue";
import { useRouter } from "vue-router";
import Star from "@/presentation/components/star-profile/StarProfile.vue";
import PopupCroppie from "@/presentation/components/popup-croppie/PopupCroppie.vue";
import DIContainer from "@/core/DIContainer";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import LeftSizePreloader from "@/presentation/components/preloader/left-side-preloader/LeftSizePreloader.vue";
import TransformUtil from "@/commons/TransformUtil";
import { getCurrentOrganizationId } from "@/commons/UserStatusUtis";
export default defineComponent({
    name: "OrganizationProfile",
    components: {
        Star: Star,
        PopupCroppie: PopupCroppie,
        LeftSizePreloader: LeftSizePreloader
    },
    props: [
        "profile",
        "rating",
        "guest",
        "accountId"
    ],
    setup: function (props) {
        var router = useRouter();
        var store = useStore();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            uploadfile: {
                image: null,
                name: null
            },
            rating: {
                point: 0,
                count: 0,
                signatePoint: "-"
            },
            orgProfileMenuInfo: {},
            showCroppie: false,
            isOwner: false,
            preload: true,
            profile: null
        });
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var uploadImageNotes = [
            "3MBまでアップロード可能",
            "画像サイズは 160px x 160px 以上推奨",
            "jpeg, jpg, png 対応"
        ];
        function onFileChange(e) {
            if (!e) {
                return;
            }
            state.orgProfileMenuInfo.image = e;
            var dataUpdate = {
                image: e
            };
            organizationInteractor.updateMyProfile(dataUpdate);
        }
        watchEffect(function () {
            state.rating.point = props.rating && props.rating.rating_ave ? TransformUtil.pointRound(props.rating.rating_ave) : 0;
            state.rating.count = props.rating && props.rating.total_count ? TransformUtil.formatNumber(props.rating.total_count) : 0;
            state.uploadfile.image = props.profile && props.profile.image_file_name ? props.profile.image_file_name : null;
        });
        watch(function () { return props.accountId; }, function (newVal, oldVal) {
            getProfile(newVal);
        }, { immediate: true });
        function getProfile(accountId) {
            if (accountId == null) {
                return;
            }
            organizationInteractor.getOrganProfile(props.accountId)
                .then(function (result) {
                state.isOwner = result.data.id == getCurrentOrganizationId();
            });
            organizationInteractor
                .getOrganProfileMenu(props.accountId)
                .then(function (result) {
                state.orgProfileMenuInfo = result.data;
                store.commit("setOrganizationMenuInfo", result.data);
                state.orgProfileMenuInfo.rating_ave = state.orgProfileMenuInfo.rating_ave ? TransformUtil.pointRound(state.orgProfileMenuInfo.rating_ave) : 0;
                state.preload = false;
            })
                .catch(function (error) {
                state.preload = false;
            });
        }
        onMounted(function () {
            state.profile = store.state.OrganizationProfileInfo;
        });
        return {
            state: state,
            store: store,
            props: props,
            onFileChange: onFileChange,
            uploadImageNotes: uploadImageNotes
        };
    }
});
